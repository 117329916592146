import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import howImage from "../../../../assets/images/howImage.png";
import image1 from "../../../../assets/images/image1.png";
import image2 from "../../../../assets/images/image2.png";
import image3 from "../../../../assets/images/image3.png";
import image4 from "../../../../assets/images/image4.png";
import step1 from "../../../../assets/images/step1.png";
import step2 from "../../../../assets/images/step2.png";
import step3 from "../../../../assets/images/step3.png";
import step4 from "../../../../assets/images/step4.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
  @media (max-width: 599px) {
    min-height: auto;
    padding: 100px 0 0px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "55%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-55%)")};
  max-width: ${(props) => (props.mw ? props.mw : "45%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw : "100%")};
    opacity: 0.6;
  }
`;
const AbsoluiteImageSteps = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "-10%")};
  left: ${(props) => (props.l ? props.l : "10%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-10%,-10%)")};
  max-width: ${(props) => (props.mw ? props.mw : "25%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599 ? props.mw : "20%"};
  }
`;
const ConnectGrid = styled(Grid)`
  width: 100%;
  position: relative;
  background: ${(props) =>
    props.bgc
      ? props.bgc
      : "linear-gradient(to bottom , #2D2D2D, transparent)"};
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  border-radius: ${(props) => (props.br ? props.br : "5px")};
`;
const HowToBuy = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="howtobuy">
        <AbsoluiteImage src={howImage} alt="" />
        <InnerContinerWraper data-aos="fade-up">
          <GridWraper container sx={{ marginBottom: "100px" }}>
            <GridItem container item xs={12}>
              <SectionHeading
                ta="center"
                ta599="center"
                fs="70px"
                fs599="70px"
              >
                How To Buy
              </SectionHeading>
            </GridItem>
            <CustomGrid
              container
              item
              xs={12}
              ai="space-between"
              jc="space-between"
              sx={{ minHeight: "90vh" }}
              g="30px"
            >
              <CustomGrid
                container
                item
                xs={12}
                ai="start"
                jc="space-between"
                g="30px"
              >
                <ConnectGrid
                  container
                  item
                  xs={12}
                  sm={4}
                  sx={{mt:{xs:0,sm:"50px"}}}
                  p="30px 10px 10px 5px"
                >
                  <AbsoluiteImageSteps src={step1} />
                  <CustomGrid item xs={3}>
                    <DataImage src={image1} />
                  </CustomGrid>
                  <CustomGrid item xs={9} p="0 0 0 5px" fd="column" ai="start">
                    <Paragraph ta599="left" fs="12px" fs599="14px">
                      Create A Metmask Wallet
                    </Paragraph>
                    <Paragraph ta599="left" fs="10px" fs599="12px">
                      Create a Metmask Wallet using either a desktop computer or
                      an iOS/Android mobile device. That will allow you to buy,
                      sell, send, and receive Grok Inu.
                    </Paragraph>
                  </CustomGrid>
                </ConnectGrid>
                <ConnectGrid
                  container
                  item
                  xs={12}
                  sm={4}
                  p="30px 10px 10px 5px"
                  
                >
                  <AbsoluiteImageSteps src={step2} />
                  <CustomGrid item xs={3}>
                    <DataImage src={image2} />
                  </CustomGrid>
                  <CustomGrid item xs={9} p="0 0 0 5px" fd="column" ai="start">
                    <Paragraph ta599="left" fs="12px" fs599="14px">
                      Send ETH To Your Wallet
                    </Paragraph>
                    <Paragraph ta599="left" fs="10px" fs599="12px">
                      You can buy Ethereum(ETH) directly on Metamask or transfer
                      it to your Metamask wallet from exchange like Binance,
                      Coinbase, Okx, etc.
                    </Paragraph>
                  </CustomGrid>
                </ConnectGrid>
              </CustomGrid>
              <CustomGrid container item xs={12} jc="space-between" g="30px" ai="end">
                <ConnectGrid
                  container
                  item
                  xs={12}
                  sm={4}
                  sx={{mb:{xs:0,sm:"50px"}}}
                  p="30px 10px 10px 5px"
                >
                  <AbsoluiteImageSteps src={step3} />
                  <CustomGrid item xs={3}>
                    <DataImage src={image3} />
                  </CustomGrid>
                  <CustomGrid item xs={9} p="0 0 0 5px" fd="column" ai="start">
                    <Paragraph ta599="left" fs="12px" fs599="14px">
                      Connect Your Wallet
                    </Paragraph>
                    <Paragraph ta599="left" fs="10px" fs599="12px">
                      Go to Uniswap and connect your wallet by clicking 'Connect
                      Wallet' and selecting Metamask.
                    </Paragraph>
                  </CustomGrid>
                </ConnectGrid>
                <ConnectGrid
                  container
                  item
                  xs={12}
                  sm={4}
                  
                  p="30px 10px 10px 5px"
                >
                  <AbsoluiteImageSteps src={step4} />
                  <CustomGrid item xs={3}>
                    <DataImage src={image4} />
                  </CustomGrid>
                  <CustomGrid item xs={9} p="0 0 0 5px" fd="column" ai="start">
                    <Paragraph ta599="left" fs="12px" fs599="14px">
                      Swap ETH For Grok Inu
                    </Paragraph>
                    <Paragraph ta599="left" fs="10px" fs599="12px">
                      Press 'Select a token' and enter the token contract
                      address to fine Grok Inu and start your swap from ETH to
                      Grok Inu.
                    </Paragraph>
                  </CustomGrid>
                </ConnectGrid>
              </CustomGrid>
            </CustomGrid>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HowToBuy;
