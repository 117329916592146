import React from "react";
import { Container, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import heroImage from "../../../../assets/images/heroImage.png";
import white from "../../../../assets/images/white.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
    /* min-height: calc(100vh - 200px); */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  /* border-radius: 20px; */
  position: relative;
  background-image: url(${white});
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const Heading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "150px")};
  font-family: ${(props) => (props.fm ? props.fm : "JosefinSans")};
  line-height: 1.2;
  color: #fff;
  /* -webkit-text-stroke: 1px #000; */
  @media (max-width: 1099px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "120px")};
  }
  @media (max-width: 899px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "90px")};
  }

  @media (max-width: 599px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "70px")};
  }
`;

const HeroSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="home">
      <InnerContinerWraper>
        <LockerMenu />
        <CustomGrid
          container
          jc="start"
          p="50px 0"
          fd599="column-reverse !important"
          data-aos="fade-up"
        >
          <CustomGrid container item xs={12} sm={7} fd="column" ai="start">
            <CustomGrid item xs={12} jc="start">
              <Heading>Grok Inu</Heading>
            </CustomGrid>
            <CustomGrid item xs={12} jc="start">
              <SectionHeading>
                xAI’s first robo dog is Grok Inu,
                <br /> a system with a byte of humour!
              </SectionHeading>
            </CustomGrid>
            <CustomGrid item xs={12} jc="start" p="20px 0">
              <div
                style={{
                  padding: "10px",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                <Paragraph
                  color="#000"
                  fm="PoppinsSB"
                  sx={{ wordBreak: "break-all" }}
                >
                  Contract Address: 0x14d1d5514701c38e2b9c5ab9432cd3ec03a61dc0
                </Paragraph>
              </div>
            </CustomGrid>
          </CustomGrid>
          <CustomGrid item xs={12} sm={5} data-aos="zoom-in">
            <DataImage mw="70%" src={heroImage} alt="" />
          </CustomGrid>
        </CustomGrid>
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default HeroSection;
