import React from "react";
import logo from "../../../../assets/images/logo.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage } from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="50px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <MenuLink
          p="0 5px 0 10px"
          href="https://t.me/Grokinu_erc20"
          target="blank"
        >
          <CustomButton p="4px 10px" sx={{letterSpacing:"-1px"}} >
          Join Telegram Community
          </CustomButton>
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
