import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import x from "../../../../assets/images/x.png";
import footerImage from "../../../../assets/images/footerImage.png";
import Paragraph from "../../Paragraph";
import TelegramIcon from "@mui/icons-material/Telegram";
const ContinerWraper = styled(Container)`
  width: 100%;
  background-color: #fff;
  min-height: 70vh;
  padding: 0px !important;
  margin-top: 300px !important;
  position: relative;
  display: flex;
  align-items: end;
  @media (min-width: 1440px) {
    min-height: 60vh;
    margin-top: 100px !important;
  }
  @media (max-width: 599px) {
    min-height: 50vh;
    margin-top: 100px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0px !important;
  border-radius: 20px;
  z-index: 1;
  @media (max-width: 599px) {
    /* padding: 0 20px !important; */
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  row-gap: 10px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "-22%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-22%)")};
  max-width: ${(props) => (props.mw ? props.mw : "28%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "50%")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "70%")};
  }
  /* @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  } */
`;
const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="fetures">
        <AbsoluiteImage src={footerImage} alt=""/>
        <InnerContinerWraper data-aos="fade-up">
          <GridWraper container>
            <GridItem container item xs={12}>
              <SectionHeading
                sx={{ lineHeight: "1px" }}
                ta="center"
                ta599="center"
                fs="70px"
                fs599="70px"
                c="#000"
              >
                Disclamer
              </SectionHeading>
            </GridItem>
            <GridItem item xs={12} sm={10}>
              <Paragraph color="#000" ta="center">
                Investing in Grokinu involves inherent risks, akin to the
                dynamic nature of the crypto market. Users are advised to
                conduct thorough research, acknowledge potential market
                volatility, and actively engage in the community with a sense of
                responsibility. Past performance is not indicative of future
                results.
              </Paragraph>
            </GridItem>
            <CustomGrid itam xs={12} g="20px">
              <Link href="https://twitter.com/Grokinu_erc20" target="blank"
                sx={{
                  width: "40px",
                  p: "8px",
                  background: "#000",
                  borderRadius: "10px",
                  display: "grid",
                }}
              >
                <DataImage src={x} alt="" />
              </Link>
              <Link href="https://t.me/Grokinu_erc20" target="blank"
                sx={{
                  p: "2px",
                  background: "#000",
                  borderRadius: "10px",
                  display: "grid",
                }}
              >
                <TelegramIcon sx={{ color: "#fff", fontSize: "36px" }} />
              </Link>
            </CustomGrid>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
        <div
          style={{
            display: "grid",
            placeItems: "center",
            padding: "10px 0",
          }}
        >
          <Paragraph fs="14px" ta="center">
            Copyright Ⓒ 2023 Grok Inu. All Rights Reserved.
          </Paragraph>
        </div>
    </>
  );
};

export default Footer;
