import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";
import tweet from "../../../../assets/images/tweet.jpg";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
  
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;


const Humour = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" >
        <InnerContinerWraper>
          <GridWraper container sx={{ marginBottom: "100px" }} data-aos="fade-up">
            <GridItem container item xs={12} >
              <SectionHeading
                ta="center"
                ta599="center"
                fs="70px"
                fs599="70px"
                p="0 0 50px"
              >
                Humour
              </SectionHeading>
            </GridItem>
            <GridItem item xs={12} sm={10}>
            <DataImage mw="100%" src={tweet} />
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Humour;
