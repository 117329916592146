import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import aboutImage from "../../../../assets/images/aboutImage.png";
import white from "../../../../assets/images/white.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px !important;
  position: relative;
  @media (min-width: 1400px) {
    min-height: auto;
  }
  @media (max-width: 999px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background-image: url(${white});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0px !important;
  position: relative;
  z-index: 1;
  @media (min-width: 1400px) {
    min-height: auto;
  }
  @media (max-width: 999px) {
    min-height: auto;
  }
  @media (max-width: 599px) {
    background-image: none;
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 50px 0px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  @media (max-width: 599px) {
    align-items: start;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <GridWraper container>
            <GridItem container item xs={8} sm={3} data-aos="zoom-in">
              <DataImage mw="100%" src={aboutImage} />
            </GridItem>
            <GridItem container item xs={12} sm={6} data-aos="fade-up">
              <SectionHeading
                p="50px 0 0"
                ta="left"
                ta599="left"
                fs="70px"
                fs599="70px"
              >
                About
              </SectionHeading>
              <Grid item xs={12}>
                <Paragraph ta="left" ta599="left" p="10px 0">
                  Grokinu is not just a crypto token; it's a digital companion
                  in the world of ERC-20 projects. Born from the ingenuity of
                  xAI, Grokinu is a one of a kind robo dog. Designed to bring a
                  unique blend of wit and functionality, Grok Inu is more than a
                  financial investment; it's a venture into a community-driven
                  space. <br />
                  <br />
                  Grokinu aims to create a crypto environment that goes beyond
                  gains, emphasizing the importance of a collective journey.
                  Join the Grokinu pack today!
                </Paragraph>
                <CustomGrid item xs={12} g="10px" p="10px 0 0">
                  <CustomButton
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x14d1d5514701c38e2b9c5ab9432cd3ec03a61dc0&chain=ethereum"
                    target="blank"
                  >
                    Buy On Uniswap
                  </CustomButton>
                  <CustomButton
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0x14d1d5514701c38e2b9c5ab9432cd3ec03a61dc0"
                    target="blank"
                  >
                    View On Dextools
                  </CustomButton>
                  <CustomButton
                    href="https://etherscan.io/token/0x14d1d5514701c38e2b9c5ab9432cd3ec03a61dc0"
                    target="blank"
                  >
                    View On Etherscan
                  </CustomButton>
                </CustomGrid>
              </Grid>
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
