import React, { useEffect } from "react";
import HeroSection from "../../Components/Sections/S1-Hero";
import { PageWrapper } from "../../Styles/style";
import AboutSection from "../../Components/Sections/S2-About";
import AOS from "aos";
import "aos/dist/aos.css";
import Humour from "../../Components/Sections/S3-Humour";
import HowToBuy from "../../Components/Sections/S4-HowToBuy";
import Footer from "../../Components/Sections/S10-Footer";
import styled from "@emotion/styled";
import video from "../../../assets/images/video.mp4";
const VideoBk = styled.video`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  opacity: 0.3;
  /* background-color: #000; */
  position: fixed;
`;
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <VideoBk muted loop autoPlay>
        <source src={video} type="video/mp4" />
      </VideoBk>
      <HeroSection />
      <AboutSection />
      <Humour />
      <HowToBuy />
      <Footer />
    </PageWrapper>
  );
};

export default MainPage;
